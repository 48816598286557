import { Data, getLocalStorageValue, Sensor } from '@xompass/web-sdk';

import {
  Detection,
  EventDetection,
  FaceDetection,
  Frame,
  ObjectRecognitionDetection,
  PoseDetectionDetectionKeyPoints,
  ReferrerSensorData,
  SensorDataFile,
  SensorDataWithoutReferrer,
} from '../types/SensorData/sensorData';

type XYPoint = {
  x: number | null;
  y: number | null;
};

export function getConcreteData(
  sensor: Sensor,
  data: Data | undefined
): {
  sensorId?: string;
  assetId?: string;
  data?: Data;
} {
  if (!data) {
    return {};
  }

  if (sensor.type === 'Referrer') {
    const referrerContent = data.content as ReferrerSensorData;
    return {
      sensorId: referrerContent.sensorId,
      assetId: referrerContent.assetId,
      data: referrerContent.data as Data,
    };
  } else {
    return {
      sensorId: sensor.id,
      data: data,
    };
  }
}

/**
 * Returns the four points of the bounding box of a detection
 */
export function getBoundingBox(
  frame: Frame,
  mode: 'center-middle' | 'left-top' = 'center-middle'
) {
  const [xMode, yMode] = mode.split('-');

  const points: XYPoint[] = [
    { x: null, y: null },
    { x: null, y: null },
    { x: null, y: null },
    { x: null, y: null },
  ];

  switch (xMode) {
    case 'left':
      points[0].x = points[3].x = frame.x;
      points[1].x = points[2].x = frame.x + frame.w;
      break;
    case 'center':
    default:
      points[0].x = points[3].x = frame.x - frame.w / 2;
      points[1].x = points[2].x = frame.x + frame.w / 2;
      break;
  }

  switch (yMode) {
    case 'top':
      points[0].y = points[1].y = frame.y;
      points[2].y = points[3].y = frame.y + frame.h;
      break;
    case 'middle':
    default:
      points[0].y = points[1].y = frame.y - frame.h / 2;
      points[2].y = points[3].y = frame.y + frame.h / 2;
      break;
  }

  return points;
}

/**
 * Returns the image url of a sensor data file
 */
export function getImageUrlFromSensorDataFile(
  sensorId: string,
  file: SensorDataFile
): string {
  const apiPath = import.meta.env.VITE_API_PATH;
  const accessToken = getLocalStorageValue('vsaas$accessToken');

  let url = `${apiPath}/sensors/${sensorId}/datasets/${file.container}/download/${file.name}?access_token=${accessToken}`;
  if (file.datasourceName) {
    url += '&datasourceName=' + file.datasourceName;
  }

  if (import.meta.env.VITE_USE_STORAGE_SIGNED_URL) {
    url += '&signed-url=true';
  }

  return url;
}
/**
 * Returns the video url of a sensor data file
 */
export function getVideoUrlFromSensorDataFile(
  sensorId: string,
  file: SensorDataFile
): string {
  const apiPath = import.meta.env.VITE_API_PATH;
  const accessToken = getLocalStorageValue('vsaas$accessToken');

  let url = `${apiPath}/sensors/${sensorId}/datasets/${file.container}/download/${file.name}?access_token=${accessToken}`;
  if (file.datasourceName) {
    url += '&datasourceName=' + file.datasourceName;
  }

  url += '&signed-url=true';

  return url;
}

/**
 * Returns the frame of a pose detection
 */
export function getPoseFrame(
  keypoints: PoseDetectionDetectionKeyPoints
): Frame | null {
  const min = { x: Infinity, y: Infinity };
  const max = { x: -Infinity, y: -Infinity };

  const keys = Object.keys(
    keypoints
  ) as (keyof PoseDetectionDetectionKeyPoints)[];

  for (const key of keys) {
    if (!keypoints[key].probability) {
      continue;
    }

    const value = keypoints[key];

    const x = value.point.x;
    const y = value.point.y;

    min.x = min.x === null || min.x > x ? x : min.x;
    min.y = min.y === null || min.y > y ? y : min.y;

    max.x = max.x === null || max.x < x ? x : max.x;
    max.y = max.y === null || max.y < y ? y : max.y;
  }

  if (
    min.x === Infinity ||
    min.y === Infinity ||
    max.x === -Infinity ||
    max.y === -Infinity
  ) {
    return null;
  }

  return {
    x: (max.x + min.x) / 2,
    y: (max.y + min.y) / 2,
    w: max.x - min.x,
    h: max.y - min.y,
  };
}

/**
 * Returns true if a point is inside a zone.
 * Used to check if a detection centroid is inside the zone configured in the sensor.
 */
export function isInsideZone(
  point: { x: number; y: number },
  zone: { x: number; y: number }[] | { x: number; y: number }[][] | undefined
): boolean {
  if (!zone) {
    return true;
  }

  const x = point.x;
  const y = point.y;

  const _zones = (Array.isArray(zone?.[0]) ? zone : [zone]) as {
    x: number;
    y: number;
  }[][];

  for (const zone of _zones) {
    let inside = false;
    for (let i = 0, j = zone.length - 1; i < zone.length; j = i++) {
      const xi = zone[i].x;
      const yi = zone[i].y;
      const xj = zone[j].x;
      const yj = zone[j].y;

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) {
        inside = !inside;
      }
    }

    if (inside) {
      return true;
    }
  }

  return false;
}

/**
 * Returns the centroid of a detection.
 * For person detection, the centroid is in the feet.
 */
export function getDetectionCentroid(
  detection: {
    frame: Frame;
    class?: string;
  },
  sensorType: string | undefined,
  renderMode: 'center-middle' | 'left-top' = 'center-middle'
): { x: number; y: number } {
  if (renderMode === 'left-top') {
    const x = detection.frame.x;
    const y = detection.frame.y;
    const h = detection.frame.h;
    const w = detection.frame.w;

    return { x: x + w / 2, y: y + h / 2 };
  }

  // The centroid of a person detection is in the feet
  if (
    detection.class !== 'person' &&
    sensorType !== 'MultiZoneObjectTracking'
  ) {
    return { x: detection.frame.x, y: detection.frame.y };
  }

  const height = detection.frame.h / 2;
  return { x: detection.frame.x, y: detection.frame.y + height };
}

export function getSequenceElementDetections(
  content: SensorDataWithoutReferrer,
  imageMeta: SensorDataFile
) {
  if (typeof content !== 'object') {
    return [];
  }

  if (content.detections) {
    return content.detections.filter(
      (detection) => detection.frameId === imageMeta.originalFilename
    );
  }

  return [];
}

export function isFiltered(
  detection: ObjectRecognitionDetection | FaceDetection | Detection,
  zone: { x: number; y: number }[] | { x: number; y: number }[][] | undefined,
  sensorType: string | undefined,
  options: {
    renderMode: 'center-middle' | 'left-top';
    filterStopped?: boolean;
  },
  detections?: (ObjectRecognitionDetection | FaceDetection | Detection)[]
): boolean {
  const det = detection as EventDetection;

  const fight =
    ('label' in det && det.label === 'FIGHT') ||
    ('class' in det && det.class === 'FIGHT');

  if (fight) {
    return false;
  }

  if (options.filterStopped) {
    return (detection as any).stopped === true;
  }

  const noPlateDetected =
    'noPlateDetected' in detection && detection.noPlateDetected;
  const triggerSubject =
    'triggerSubject' in detection && detection.triggerSubject;
  const missingContainee =
    'missingContainee' in detection && detection.missingContainee;

  if (noPlateDetected || triggerSubject || missingContainee) {
    return true;
  }

  if (sensorType === 'OverlapDetection' && Array.isArray(detections)) {
    if ('filteredBy' in detection) {
      return Object.values(detection.filteredBy || {}).every((c) => c);
    }

    const idx = detections.findIndex((d) => d === detection);
    for (const det of detections) {
      const isFiltered =
        'filteredBy' in det &&
        Object.values(det.filteredBy || {}).every((c) => c);

      if (!isFiltered) {
        continue;
      }

      const overlapsWith: number[] =
        'overlapsWith' in det && Array.isArray(det.overlapsWith)
          ? det.overlapsWith
          : [];

      if (overlapsWith.includes(idx)) {
        return true;
      }
    }

    return false;
  }

  let filtered = true;

  if (det.filteredBy) {
    if (det.filteredBy.BLACKLIST) {
      return true;
    }

    filtered = Object.values(det.filteredBy).every((c) => c);

    if (det.filteredBy.ZONE !== undefined) {
      return filtered;
    }
  }

  if (!filtered || !zone) {
    return false;
  }

  const centroid = getDetectionCentroid(
    detection,
    sensorType,
    options.renderMode
  );
  return isInsideZone(centroid, zone);
}
